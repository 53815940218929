.topUI{
    width: 10%;
    height: 15%;
    position: absolute;
    left: 0px;
    top: 0px;
}

.topStick{
    position: -webkit-sticky;
    position: sticky;
    width: 35%;
    height: 15%;
    top: 0;
    border: cyan 1px;
    background-color: blue ;
}

.moneyCounter{
    background-color: red;
}

.moneyRate{
    background-color: green;
}

.satisf{
    background-color: yellow;
}

.morale{
    background-color: purple;
}

